@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.listWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 20px 20px 0;
  margin-bottom: 30px;
  border-top: 1px solid $baseTextColor;
  z-index: -1;
  overflow: auto;

  .redactionsList {
    margin: 0;
    padding: 0;
    list-style: none;

    .redactionsItem {
      position: relative;
      color: $backgroundFormColor;
      padding: 10px 30px 10px 10px;
      background: #1f1f1f;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .itemText {
        word-wrap: break-word;
        word-break: break-word;
        white-space: normal;
      }

      &.isDeleted {
        color: $backgroundFormColor;
        text-decoration: line-through;
      }

      &:not(:last-child) {
        margin-bottom: 3px;
      }

      .count {
        color: $secondary3Color;
        position: absolute;
        top: 13px;
        right: 10px;
        font-size: 12px;
        line-height: 17px;
        width: 20px;
        height: 12px;
        display: flex;
        justify-content: right;
        align-items: center;
      }

      .spinner {
        position: absolute;
        left: unset;
        right: 10px;
        top: 18px;
        border-width: 1px;
        border-color: $secondary3Color;
        border-top-color: #fff;
      }

      .spinnerOverlay {
        opacity: 0.6;
        background: #1f1f1f;
      }

      &:hover {
        cursor: pointer;
        background: #000;

        .deleteButton {
          display: block;
        }

        .count {
          display: none;
        }
      }

      &:has(.spinnerOverlay):hover {
        cursor: default;

        .deleteButton {
          display: none;
        }
      }

      .deleteButton {
        display: none;
        position: absolute;
        top: 13px;
        right: 10px;
        width: 12px;
        height: 12px;
        fill: $cancelColor;
      }

      .restoreButton {
        @extend .deleteButton;
        fill: $ColorGrayscaleWhite;
      }
    }
  }
}
