@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/helpers/align.scss";

.redactWrp {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
  margin-bottom: 20px;

  .previewDescription {
    font-size: 12px;
    line-height: 17px;
    color: $secondary3Color;
  }
}

.header {
  @extend .flexStart;
  margin-bottom: 20px;

  .title {
    color: $ColorGrayscaleWhite;
    margin: 0 5px 0 0;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.14px;
  }

  .tooltipWrapper {
    cursor: pointer;
    height: 16px;

    :hover .tooltipIcon {
      fill: $ColorGrayscaleGray5;
    }

    .tooltipIcon {
      fill: $ColorGrayscaleGray1;
    }
  }

  .closeButton {
    margin-left: auto;

    i {
      width: 12px;
      height: 12px;
      margin: 0;

      svg {
        fill: $ColorGrayscaleWhite;
      }
    }
  }
}

.tooltip {
  div:nth-last-child(1) {
    padding: 8px 14px;
    background: $ColorGrayscaleGray1;
  }

  div:nth-last-child(2) {
    border-left-color: $ColorGrayscaleGray1;
  }
}

.toggleWrapper {
  @extend .flexStart;

  .toggleButton {
    margin-right: 10px;
    margin-left: 5px;

    span {
      background: #000;

      &:before {
        background: $baseTextColor;
      }
    }
  }

  .toggleTitle {
    color: $secondary3Color;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
  }
}

.toolsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 0 20px;

  .fieldRadioGroup {
    margin-bottom: 20px;
  }

  .radioGroup {
    display: flex;
    border: 1px solid $ColorGrayscaleGray1;
    border-radius: 5px;
    overflow: hidden;

    .radio {
      @extend .flexCenter;
      width: 50%;
      padding: 12px 0;
      margin-right: 0;

      &:hover {
        cursor: pointer;
      }

      &:first-child {
        border-right: 1px solid $ColorGrayscaleGray1;
      }

      svg {
        fill: $ColorGrayscaleGray2;
        width: 12px;
        height: 12px;
      }

      .radioLabel {
        padding-left: 0;
        height: 12px;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    .radioChecked {
      background: #424242;
      border: 0;

      svg {
        fill: $ColorGrayscaleWhite;
      }
    }

  }
}

.actionBtnWrapper {
  padding: 0 20px 20px;
  text-align: center;
  margin-top: auto;

  .removeAllButton {
    color: $cancelColor;
    margin-bottom: 30px;
    font-weight: bold;
    letter-spacing: 0.01em;

    &:hover,
    &:focus {
      color: $cancelColorHover;
    }

    &.disabled {
      color: $baseTextColor;
      background: transparent;
      line-height: 18px;
      height: 18px;
      margin-right: 0;
    }
  }

  .applyAllButton {
    width: 100%;

    &.disabled {
      background: $baseTextColor;
      color: $secondary3Color;
    }
  }
}

